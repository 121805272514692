import React from "react";
import { graphql } from 'gatsby';
import TransitionLink from "gatsby-plugin-transition-link";
import Img from "gatsby-image";
import Header from "../components/header.js";
import "../sass/norihall.scss";


export const query = graphql`
  {
    prismic {
      home(uid: "home", lang: "en-us") {
        galleries {
          gallery {
            ... on PRISMIC_Gallery {
              title
              gallery_thumbnail_image
              gallery_thumbnail_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 540, maxHeight: 540, cropFocus: CENTER, fit:COVER, sizes: "calc( 50% - 2rem ), (min-width: 768px) calc( 50% - 4rem ), (min-width: 992px) calc( 25% - 4rem )") {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`

function HomePage({ data }) {
  const galleries = data.prismic.home.galleries;
	const homeGallery = galleries.map(function(gallery, index) {
    const galleryURL = '/gallery/' + gallery.gallery._meta.uid;
		return (
      <li key={index}>
        <TransitionLink 
          to={galleryURL}
          exit={{
            length: 0.75
          }}
          entry={{
            delay: 0.1
          }}
        >
          <Img fluid={gallery.gallery.gallery_thumbnail_imageSharp.childImageSharp.fluid} alt={gallery.gallery.gallery_thumbnail_image.alt} />
        </TransitionLink>
      </li>
		)
	})
	return (
    <>
      <Header />
      <div id="content">
        <h1>Galleries</h1>
    		<ul className="gallery">
    			{homeGallery}
    		</ul>
      </div>
    </>
	)
}

export default HomePage
